import {WorkspaceTeamRole} from "../types/workspace.types";

export class WorkspacePermissionUtils {

    static readonly _TEAM_ROLE_RANKS = new Map<string, number>([
        [WorkspaceTeamRole.VIEWER, -1],
        [WorkspaceTeamRole.MEMBER, 0],
        [WorkspaceTeamRole.GUEST_EDITOR, 0],
        [WorkspaceTeamRole.EDITOR, 1],
        [WorkspaceTeamRole.ADMIN, 2],
        [WorkspaceTeamRole.OWNER, 3],
        [WorkspaceTeamRole.SUPPORT, 4],
        [WorkspaceTeamRole.SUPER_ADMIN, 5],
    ]);
}