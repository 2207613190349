import {Injectable} from '@angular/core';
import {User} from '../models/user';
import {DeskManagerHelperService} from './desk-manager-helper.service';
import {ActionMenuOption} from '../models/action-menu-option';
import {PlanHelperService} from "./plan-helper.service";
import {UserHelperService} from "./user-helper.service";
import {PlanAuthority} from "../models/plan";
import {PermissionRequester, WorkspaceTeamRole} from "../types/workspace.types";
import {WorkspacePermissionUtils} from "../configs/workspace-permission-utils";

@Injectable({
    providedIn: 'root'
})
export class WorkspacePermissionService {

    private readonly _GUEST_ROLES: string[] = [
        WorkspaceTeamRole.GUEST_EDITOR,
        WorkspaceTeamRole.VIEWER,
    ];

    private _currentUserPromise: Promise<User>;

    private get _ranksMap(): Map<string, number> {
        return WorkspacePermissionUtils._TEAM_ROLE_RANKS;
    }

    constructor(
        private planHelperService: PlanHelperService,
        private userHelperService: UserHelperService,
        private workspaceHelperService: DeskManagerHelperService,
    ) {

    }

    getRankByRole = (role: WorkspaceTeamRole): number => {
        return this._ranksMap.get(role);
    }

    canChangeTeamPermission = async (): Promise<boolean> => {
        const currentUser = await this._getCurrentUser();
        return await this.adminOrHigherPermissions(currentUser)
    }

    canTransferOwnership = async (applyTo: PermissionRequester): Promise<boolean> => {
        const currentUser = await this._getCurrentUser();
        return (
            currentUser.id !== applyTo.userId &&
            await this.adminOrHigherPermissions(currentUser) &&
            await this.adminOrHigherPermissions(applyTo)
        );
    }

    canDeleteMember = async (applyTo: PermissionRequester): Promise<boolean> => {
        const currentUser = await this._getCurrentUser();
        const currentUserRank = this._ranksMap.get(currentUser.type);
        const memberRank = this._ranksMap.get(applyTo.type);

        return (
            applyTo &&
            currentUser &&
            currentUser.id !== applyTo.userId &&
            (currentUserRank >= memberRank || await this._isOwner(currentUser.id)) &&
            !await this._isOwner(applyTo.userId)
        );
    }

    canDismissMember = async (applyTo: PermissionRequester): Promise<boolean> => {
        const currentUser = await this._getCurrentUser();
        const currentUserRank = this._ranksMap.get(currentUser.type);
        const memberRank = this._ranksMap.get(applyTo.type);

        return (
            applyTo &&
            currentUser &&
            currentUser.id !== applyTo.userId &&
            (currentUserRank > memberRank) &&
            !await this.adminOrHigherPermissions(applyTo)
        );
    }

    canResendWorkspaceInvitation = async (applyTo: PermissionRequester): Promise<boolean> => {
        const currentUserRank = this._ranksMap.get((await this._getCurrentUser()).type);
        const minRank = this._ranksMap.get(WorkspaceTeamRole.ADMIN);
        return (
            currentUserRank >= minRank &&
            (applyTo.status === 100 || (applyTo.foreignUser && !applyTo.approved))
        );
    }

    canChangeRoles = async (memberId: string, role: string): Promise<boolean> => {
        const currentUser = await this._getCurrentUser();
        const currentUserRank = this._ranksMap.get(currentUser.type);
        const appliedToUserRank = this._ranksMap.get(role);
        return (
            currentUser &&
            await this.adminOrHigherPermissions(currentUser) &&
            currentUserRank >= appliedToUserRank &&
            currentUser.id !== memberId &&
            !await this._isOwner(memberId) &&
            !this._GUEST_ROLES.includes(role)
        );
    }

    canChangeWorkspaceDetails = async (): Promise<boolean> => {
        const currentUser = await this._getCurrentUser();
        return (
            currentUser && await this.adminOrHigherPermissions(currentUser)
        );
    }

    canCopyWorkspaceMember = async (): Promise<boolean> => {
        const currentUser = await this._getCurrentUser();
        return (
            currentUser && await this.adminOrHigherPermissions(currentUser)
        );
    }

    canAddMembersToWorkspace = async (): Promise<boolean> => {
        const currentUser = await this._getCurrentUser();
        return (
            currentUser && await this.adminOrHigherPermissions(currentUser)
        );
    }


    canDeleteWorkspace = async (): Promise<boolean> => {
        const currentUser = await this._getCurrentUser();
        return (
            currentUser && await this.adminOrHigherPermissions(currentUser)
        );
    }

    canRenameOrganization = async (): Promise<boolean> => {
        const currentUser = await this._getCurrentUser();
        return (
            currentUser && await this.adminOrHigherPermissions(currentUser)
        );
    }

    canViewBillingAndPricing = async (): Promise<boolean> => {
        const currentUser = await this._getCurrentUser();
        return (
             await this._isOwner(currentUser.userId) &&
            !await this.planHelperService.isAppsumoLifetimePlan() &&
            !await this.isViewer()
        );
    }

    canViewBilling = async (): Promise<boolean> => {
        const currentUser = await this._getCurrentUser();
        const plan = await this.planHelperService.getOrganizationPlansDetails();
        return (
            await this._isOwner(currentUser.userId) &&
            !await this.planHelperService.isAppsumoLifetimePlan() &&
            !await this.isViewer() &&
            plan.authority != PlanAuthority.ANDROID &&
            plan.authority != PlanAuthority.IOS
        );
    }

    canViewCollaborateScreen = async (): Promise<boolean> => {
        const currentUser = await this._getCurrentUser();
        return (
            currentUser.type === WorkspaceTeamRole.EDITOR ||
            currentUser.type === WorkspaceTeamRole.MEMBER ||
            await this.adminOrHigherPermissions(currentUser)
        );
    }

    canInviteExternals = async (role: string): Promise<boolean> => {
        const currentUser = await this._getCurrentUser();
        const currentUserRank = this._ranksMap.get(currentUser.type);
        let minRank: number;
        if (role === WorkspaceTeamRole.GUEST_EDITOR) {
            minRank = this._ranksMap.get(WorkspaceTeamRole.ADMIN);
        } else if (role === WorkspaceTeamRole.VIEWER) {
            minRank = this._ranksMap.get(WorkspaceTeamRole.EDITOR);
        }

        return currentUserRank >= minRank;
    }

    filterOptionsByRank = async (options: ActionMenuOption[]): Promise<ActionMenuOption[]> => {
        const currentUser = await this._getCurrentUser();
        const currentUserRank = this._ranksMap.get(currentUser.type);
        return (options || []).filter(action => currentUserRank >= action.getRank());
    }

    isViewer = async (): Promise<boolean> => {
        const currentUser = await this._getCurrentUser();
        return currentUser.type === WorkspaceTeamRole.VIEWER;
    }

    isGuest = async (): Promise<boolean> => {
        const currentUser = await this._getCurrentUser();
        return this._GUEST_ROLES.includes(currentUser.type);
    }


    isGuestEditor = async (): Promise<boolean> => {
        const currentUser = await this._getCurrentUser();
        return currentUser.type === WorkspaceTeamRole.GUEST_EDITOR;
    }

    isUserTeam = async (): Promise<boolean> => {
        const currentPlan = await this.planHelperService.getOrganizationPlansDetails();
        return this.planHelperService.isTeamPlan(currentPlan.name) || this.planHelperService.isAIMaxPlan(currentPlan.name);
    }

    clear = (): void => {
        this._currentUserPromise = null;
    }


    canViewOrganizationInfoPanel = async (): Promise<boolean> => {
        const currentUser = await this._getCurrentUser();
        return await this.isUserTeam() && this.adminOrHigherPermissions(currentUser)
    }

    private _getCurrentUser = (): Promise<User> => {
        return this._currentUserPromise || this.userHelperService.getUserDetails();
    }

    private _hasAdminPermissions = (type: string): boolean => {
        return type === WorkspaceTeamRole.ADMIN;
    }

    private adminOrHigherPermissions = async (currentUser) => {
        return (
            this._hasAdminPermissions(currentUser.type) ||
            currentUser.type === WorkspaceTeamRole.SUPPORT ||
            currentUser.type === WorkspaceTeamRole.SUPER_ADMIN ||
            await this._isOwner(currentUser.userId)
        )
    }


    private _isOwner = async (memberId: string): Promise<boolean> => {
        const organizationOwner = (await this.workspaceHelperService.getOrganizationData()).owner;
        return (
            organizationOwner &&
            memberId === organizationOwner.id
        );
    }

}
